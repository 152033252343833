<template>
  <div class="product-components-skylight-lamp-wrapper">
    <div
      v-if="isLoad"
      class="lamp-list"
    >
      <template
        v-for="(item, index) in list"
      >
        <div
          :key="index"
          class="lamp-list-list"
        >
          <div class="lamp-list-list-img">
            <img :src="cover(item)" />
          </div>
          <div class="lamp-list-list-title">{{ item.title }}</div>
          <div
            class="lamp-list-list-more"
            @click="getProduct(item)"
          >
            {{ $t('learnMore') }}
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>

import { goGet } from '@/utils'
export default {
  name: 'ProductLLamp',
  metaInfo() {
    return {
      title: this.$t('product')
    }
  },
  data() {
    return {
      isLoad: false,
      productSkylight: []
    }
  },

  mounted() {
    this.getList()
  },

  computed: {
    cover() {
      return (item) => {
        return  process.env.VUE_APP_API + item.cover.split(',')[0]
      }
    },

    list() {
      return this.productSkylight.filter(list => list.lang === this.$i18n.locale)
    }
  },

  methods: {
    getList() {
      goGet('/ewinlight/product/list').then(res => {
        this.isLoad = true
        this.productSkylight = res.data
      })
    },
    getProduct(item) {
       this.$router.push('/product/details/' + item.id)
    }
  }
}
</script>

<style lang="scss" scoped>
.product-components-skylight-lamp-wrapper {
  background: #F5F6F7;
  width: 100%;
  padding: 20px;
  overflow: hidden;
  .lamp-list {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    &-list {
      width: 161px;
      height: 225px;
      background: #FFFFFF;
      border-radius: 12px;
      overflow: hidden;
      margin-bottom: 16px;
      padding: 18px 8px;
      &-img {
        display: flex;
        justify-content: center;
        width: 100%;
        height: 101px;
        margin-bottom: 22px;
        img {
          width: 100%;
          object-fit: cover;
        }
      }
      &-title {
        font-size: 13px;
        font-weight: bold;
        color: #222222;
        line-height: 20px;
        height: 20px;
        overflow: hidden;
        text-align: center;
      }
      &-more {
        width: 111px;
        height: 32px;
        background: #333333;
        border-radius: 6px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        color: rgba(255, 255, 255, 1);
        margin: 0 auto;
        margin-top: 12px;
      }
    }
  }
}
</style>